<!--当前页面暂无功能，仅完成静态页面-->
<template>
  <div style='height: 100%'>
    <nesi-user-common :title='`转租管理`'>
      <template #button>
        <!--        <el-button type='primary' plain @click='add'>新增</el-button>-->
      </template>
      <template #content>
        <el-table
          ref='multipleTableRef'
          :data='tableData'
          border
          stripe
          v-loading='loading'
          style='width: 100%'
          :cell-style="{ textAlign: 'center' }"
          :header-cell-style="{
            textAlign: 'center',
            backgroundColor: '#f5f7fa'
          }"
        >
          <!--          <el-table-column type="selection" width="55" />-->
          <el-table-column prop='bazmbh' label='备案证明号' width='130' />
          <el-table-column prop='house_location' label='房屋坐落' />

          <el-table-column prop='qymc' label='承租面积' width='80' />
          <el-table-column prop='qymc' label='承租人' width='80' />
          <el-table-column prop='qymc' label='承租时间' width='120' />
          <el-table-column prop='qymc' label='申请状态' width='80' />
          <el-table-column prop='qymc' label='变更时间' width='120' />
          <el-table-column label='操作' width='120' fixed='right'>
            <template #default='scope'>
              <template v-if='scope.row.status === 3'>
                <el-button
                  type='text'
                  size='small'
                  class='list-btn'
                  @click='actionStatusHandle(scope.row, true)'
                >同意
                </el-button>
                <el-button
                  type='text'
                  size='small'
                  class='list-btn'
                  @click='actionStatusHandle(scope.row, false)'
                >拒绝
                </el-button>
              </template>
              <span v-else>--</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout='total, prev, pager, next'
          :total='total'
          v-model:pageSize='pageSize'
          v-model:currentPage='currentPage'
          @size-change='pageSizeChange'
          :page-sizes='[10,20,30,50,100]'
          @current-change='handleCurrentChange'
          style='text-align: right; margin: 10px 0'
        />
      </template>
    </nesi-user-common>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import NesiUserCommon from '@/components/NesiUserCommon/index.vue'
import { getPublicSideListApi, editRecordStatusApi } from '@/api/user-deposit'
import { ElMessageBox } from 'element-plus'

const total = ref(0)
const tableData = ref([])
const pageSize = ref(10)
const multipleTableRef = ref(null)
const loading = ref(false)
const currentPage = ref(1)

/**
 * 撤销操作
 * @param record
 * @param isAgree：true同意，false拒绝
 */
function actionStatusHandle(record, isAgree) {
  const agreeMsgText = '是否同意撤销该托管？'
  const rejectMsgText = '是否拒绝撤销？'
  ElMessageBox.confirm(isAgree ? agreeMsgText : rejectMsgText, '操作提示', {
    confirmButtonText: isAgree ? '同意' : '拒绝',
    cancelButtonText: '关闭',
    type: isAgree ? 'success' : 'warning'
  })
    .then(async () => {
      const form = {
        id: record.id,
        status: isAgree ? 4 : 5
      }
      const result = await editRecordStatusApi(form)
      console.log('操作返回结果', form)
      fetchTableData()
    })
    .catch((e) => {
      console.log('操作返回结果', e)
    })
}

/**
 * 获取表格数据
 */
async function fetchTableData() {
  try {
    loading.value = true
    const result = await getPublicSideListApi({
      type: 1,
      pageNo: currentPage.value,
      pageSize: pageSize.value
    })
    tableData.value = result.records
    total.value = result.total
  } finally {
    loading.value = false
  }
}

async function handleCurrentChange() {
  await fetchTableData()
}

async function pageSizeChange(ev) {
  await fetchTableData()
}

onMounted(() => {
  // fetchTableData()
})
</script>

<style lang='scss' scoped></style>
