import request from '@/utils/request'

/**
 * 获取托管备案列表
 */
export const getPublicSideListApi = (data) => {
  return request({
    url: '/publicSide/findRecords',
    method: 'POST',
    data,
    params: data
  })
}

/**
 * 托管备案状态修改
 */
export const editRecordStatusApi = (data) => {
  return request({
    url: '/publicSide/editRecords',
    method: 'POST',
    data,
    params: data
  })
}
